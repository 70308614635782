import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Junior - Red Ball - Age 5-7`,
    job: "Very much focused on hand-eye coordination and establishing strength and timing of a swing",
    avatar: require('../../images/migrate/7.png')
    // "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "2",
    name: `Junior - Orange Ball - Age 7-9`,
    job: "Progressing from red ball or those players who have the physical ability to wield a racket",
    avatar: require('../../images/migrate/16.png')
    // "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "3",
    name: `Junior - Green Ball - Age 9-11`,
    job: "Using the full-size court to continue to grow their technical and physical abilities",
    avatar: require('../../images/migrate/10.png')
    // "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "4",
    name: `Junior - Adult Ball - Age 11+`,
    job: "At this stage, the focus is on transitioning the kids to the demands of adult tennis",
    avatar: require('../../images/migrate/20.png')
    // "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "5",
    name: `Adult - Beginner`,
    job: "Establishing the fundamental techniques and movements of the game stroke by stroke",
    avatar: require('../../images/migrate/7.png')
    // "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "6",
    name: `Adult - Intermediate`,
    job: "Players have enough experience and confidence to be comfortable serving and rallying",
    avatar: require('../../images/migrate/16.png')
    // "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "7",
    name: `Adult - Advanced`,
    job: "Players hit with intensity and are training for competitive match play and tournaments",
    avatar: require('../../images/migrate/10.png')
    // "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
 
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="Everyone is unique, having different abilities, personalities, styles and preferences. "
      >
        Learning Courses
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm flex flex-col justify-between">
            <div className="text-center mb-4">
              <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                {item.name}
              </h3>
              <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                {item.job}
              </span>
            </div>
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover rounded-full"
              src={item.avatar}
            />
          </div>
        ))}
      </div>
      <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400 mt-5">
        Tennis is an exhilarating and challenging sport that encompasses physical, technical, and mental demands. It bolsters fitness, coordination, and resilience while providing an enjoyable avenue for socializing, forming new connections and competing.
      </span>
    </div>
  );
};

export default SectionFounder;
