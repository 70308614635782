import rightImg from "images/hero-right1.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";

export interface PagePlayersProps {
  className?: string;
}

const PagePlayers: FC<PagePlayersProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PagePlayers overflow-hidden relative ${className}`}
      data-nc-id="PagePlayers"
    >
      <Helmet>
        <title>Players || Tennisbrain</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="Players."
          btnText=""
          subHeading="No matter your age there will always be areas to improve, and your coach will play a crucial role in your development."
        />

        <SectionFounder />
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PagePlayers;
