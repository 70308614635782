import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet-async";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'; // Import timeGridPlugin
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dateClick
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  formatDate,
} from '@fullcalendar/core'

import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";

export interface EventProps {
  className?: string;
  title: string;
  start: Date;
  text: string;
}

const events: EventProps[] = [
  { title: 'Meeting-Event-Coach-Player-Booking-Confirmed', start: new Date(), text: 'This is custom text' },
  { title: 'Meeting', start: new Date(), text: 'This is custom text' },
  { title: 'Meeting', start: new Date(), text: 'This is custom text' },
  { title: 'Meeting', start: new Date(), text: 'This is custom text' },
  { title: 'Meeting', start: new Date(), text: 'This is custom text' },
  { title: 'Meeting', start: new Date(), text: 'This is custom text' },
]

const PagePlayerCalenda: React.FC = () => {
  const renderEventContent = (eventContent: EventContentArg) => {
    console.log('event content', eventContent);

    return (
      <div>
        <b>{eventContent.timeText}</b>
        <i>{eventContent.event.title}</i>
        <br />
        <div>{eventContent.event.extendedProps.text}</div>
      </div>
    )
  }

  return <div className="nc-PagePlayerCalenda">
    <Helmet>
      <title>Calenda || Tennisbrain</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          Schedule your Events.{" "}
        </span>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          dayMaxEvents={true}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          events={events}
        // eventContent={renderEventContent}
        />
        <div className="pt-8">
          <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
        </div>
      </header>
    </div>
  </div>
}

export default PagePlayerCalenda;
