import rightImg from "images/migrate/me3.jpg";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Tennisbrain</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 Welcome"
          btnText=""
          subHeading=""
        >
          <div className="text-left">
            <p>Hi guys welcome to my site. I am the <a href="https://www.instagram.com/tennis_brain/">TennisBrain</a> (my superhero name according to my kids), but you can call me Sam. I am a tennis coach and a data practitioner. Tennis became my biggest passion from an early age and I now have over 30 years’ experience in the game. My first national tournament was at the age of 12, and I haven’t stopped competing since, regularly taking part in <a href="https://www.itftennis.com/en/players/samir-thakrar/800680223/gbr/vt/s/">ITF Masters Tour</a> and <a href="https://competitions.lta.org.uk/player-profile/7EA56AAC-E332-4ED0-9CF9-C4013D8DC3C7">LTA</a> Events.</p><br />
            <p>Aside from tennis, I graduated with a BSc in Statistics and Economics, and have headed up and led <a href="https://www.linkedin.com/in/samir-thakrar-86a9aa12b/">data science and insights teams</a>  at multiple large organisations in London. I have been lucky enough to combine my passion in tennis with my skills in data, and created <a href="https://www.tennisbrain.com/">www.tennisbrain.com</a> in 2018, which provides insights and predictions on professional tour matches. I've stuck to the same name with this platform.</p><br />
            <p>Despite coaching for over 20 years, only recently I have managed to step away from corporate life and fully commit to sharing my love of the game with the world. As my time on the tennis court increased and my desire to effectively track and monitor my students development www.tennisbrain.coach came to life.</p><br />
            <p>As a coach I aim to establish the technical building blocks of a tennis stroke whilst allowing a student to develop their own personality and style on the court. With additional emphasis in movement, balance, breathwork, court etiquette and intellect, I'm here to help tennis enthusiasts, both players and coaches achieve their goals, maximising their potential and have a lot of fun while doing it. For me coaching is the best job in the world, and I hope this platform can help other coaches get the most out of their time and students development.</p><br />
          </div>
        </SectionHero>

        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
