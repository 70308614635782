import rightImg from "images/hero-right1.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";

export interface PageCoachesProps {
  className?: string;
}

const PageCoaches: FC<PageCoachesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageCoaches overflow-hidden relative ${className}`}
      data-nc-id="PageCoaches"
    >
      <Helmet>
        <title>Coaches || Tennisbrain</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="Coaches."
          btnText=""
          subHeading="TennisBrain provides a unique scheduling and tracking system for coaches to manage their group and private training schedules. Students can access your availability and book times directly on your calendar, as well as access and book into any group classes you may be running throughout the week or training camps you maybe running during school holidays."
        />

        <SectionFounder />
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageCoaches;
