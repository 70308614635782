//
import feature1 from "images/migrate/reporting.jpg";
import feature2 from "images/migrate/calendar-icon-png-2.jpg";
import feature3 from "images/migrate/20.png";
import feature4 from "images/migrate/neon_brain.png";
import feature5  from "images/migrate/drill.png";
import feature6 from "images/migrate/7.png";
import feature7 from "images/migrate/8.png";
import feature8 from "images/migrate/16.png";
import feature9 from "images/migrate/neon_brain2.png";
import feature10 from "images/migrate/10.png";

//
export const featureImages = [
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
  feature9,
  feature10,
  
];
