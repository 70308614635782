import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet-async";
import CommonLayout from "./CommonLayout";

// import NcImage from "shared/NcImage/NcImage";
// import I404Png from "images/404.png";

const PageGroup: React.FC = () => (
  <div className="nc-PageGroup">
      <CommonLayout>

    <Helmet>
      <title>Group page || Tennisbrain</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          Group page
        </span>
      </header>
      <div className="container">
        <div className="pt-8">
          <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
        </div>
      </div>
    </div>
    
    </CommonLayout>

  </div>
);

export default PageGroup;
