import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";

interface Player {
    id: number;
    name: string;
    hand: string;
    gender: string;
    dob: string;
}



const PagePlayerPlayers: React.FC = () => {
    const [players, setPlayers] = useState<Player[]>([
        { id: 1, name: 'player2 A', hand: 'right', gender: 'female', dob: '1 Jan 2001' },
        // ... Add other players here
    ]);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

    const openEditDialog = (player: Player) => {
        setSelectedPlayer(player);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        setSelectedPlayer(null);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedPlayer) {
            setSelectedPlayer({ ...selectedPlayer, [event.target.name]: event.target.value });
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (selectedPlayer) {
            const updatedPlayers = players.map(player =>
                player.id === selectedPlayer.id ? selectedPlayer : player
            );
            setPlayers(updatedPlayers);
            closeDialog();
        }
    };

    return (
        <div className="nc-PagePlayerPlayers">
            <Helmet>
                <title>Players || Tennisbrain : Player</title>
            </Helmet>
            <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                {/* HEADER */}
                <header className="text-center max-w-2xl mx-auto space-y-2">
                    {/* <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                        COMMING SOON.{" "}
                    </span> */}
                    <h1 className="text-xl font-bold mb-4">Players</h1>

                </header>
                <div className="container">
                    <div className="container mx-auto p-4">
                        <h1 className="text-xl font-bold mb-4">PLAYERS</h1>
                        <table className="min-w-full bg-white border rounded">
                            <thead>
                                <tr>
                                    <th className="border px-4 py-2">#</th>
                                    <th className="border px-4 py-2">Name</th>
                                    <th className="border px-4 py-2">Playing Hand</th>
                                    <th className="border px-4 py-2">Gender</th>
                                    <th className="border px-4 py-2">Date of Birth</th>
                                    <th className="border px-4 py-2">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {players.map((player, index) => (
                                    <tr key={player.id}>
                                        <td className="border px-4 py-2">{index + 1}</td>
                                        <td className="border px-4 py-2">{player.name}</td>
                                        <td className="border px-4 py-2">{player.hand}</td>
                                        <td className="border px-4 py-2">{player.gender}</td>
                                        <td className="border px-4 py-2">{player.dob}</td>
                                        <td className="border px-4 py-2">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => openEditDialog(player)}>
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Add/Edit Dialog */}
                        {isDialogOpen && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
                                <div className="bg-white p-6 rounded w-full max-w-md">
                                    <h2 className="text-xl font-bold mb-4">Edit Player</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                                Name
                                            </label>
                                            <input
                                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={selectedPlayer?.name || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        {/* Add other input fields for hand, gender, dob */}
                                        <div className="flex justify-end">
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                                type="button"
                                                onClick={closeDialog}
                                            >
                                                Close
                                            </button>
                                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="submit">
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="pt-8">
                        <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default PagePlayerPlayers;
