import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useState, useEffect } from "react";
import ImageTennisTrain from '../../images/tennis-train.png'
import Label from "components/Label/Label";

const AccountOrder = () => {
  const [exerciseTypes, setExerciseTypes] = useState([
    {
      key: 'physical',
      name: 'Physical Excercise',
      subTypes: [
        {
          key: 'skip',
          name: 'Skip',
          description: 'This is the one of the excercises'
        },
        {
          key: 'weights',
          name: 'Weights',
          description: 'This is the one of the excercises'

        },
      ]
    },
    {
      key: 'technical',
      name: 'Technical Excercise',
      subTypes: [
        {
          key: 'skip',
          name: 'Skip',
          description: 'This is the one of the excercises'
        },
        {
          key: 'weights',
          name: 'Weights',
          description: 'This is the one of the excercises'
        },
      ]
    },
    {
      key: 'tactical',
      name: 'Tactical Excercise',
      subTypes: [
        {
          key: 'skip',
          name: 'Skip',
          description: 'This is the one of the excercises'
        },
        {
          key: 'weights',
          name: 'Weights',
          description: 'This is the one of the excercises'
        },
      ]
    },
    {
      key: 'mental',
      name: 'Mental Excercise',
      subTypes: [
        {
          key: 'skip',
          name: 'Skip',
        },
        {
          key: 'weights',
          name: 'Weights',
        },
      ]
    },
  ]);
  const renderProductItem = (product: any, index: number) => {
    const { image, name, description } = product;
    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={ImageTennisTrain}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium line-clamp-1">{name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>{ description }</span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  {/* <span>{"XL"}</span> */}
                </p>
              </div>
              {/* <Prices className="mt-0.5 ml-2" /> */}
              {/* <Label className="mt-0.5 ml-2">Premimum</Label> */}
              <button
                type="button"
                className="font-medium text-indigo-600 dark:text-primary-500 "
              >
                Edit
              </button>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Time</span>
              <span className="inline-block sm:hidden">Time</span>
              <span className="ml-2">20 mins</span>
            </p>

            <div className="flex">
              {/* <button
                type="button"
                className="font-medium text-indigo-600 dark:text-primary-500 "
              >
                Edit
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderExerciseTypes = () => {
    return exerciseTypes.map((exerciseType: any) => (
      <div key={exerciseType.key} className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-lg font-semibold">{exerciseType.name}</p>
            <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
              <span>Apr 8, 2024</span>
              <span className="mx-2">·</span>
              <span className="text-primary-500">Confirmed</span>
            </p>
          </div>
          <div className="mt-3 sm:mt-0">
            <ButtonSecondary
              sizeClass="py-2.5 px-4 sm:px-6"
              fontSize="text-sm font-medium"
            >
              Create
            </ButtonSecondary>
          </div>
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {exerciseType.subTypes.map(renderProductItem)}
        </div>
      </div>
    ))
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">Exercise Types</h2>
          {renderExerciseTypes()}
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
