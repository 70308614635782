import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";

export interface PageFAQProps {
  className?: string;
}

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: 'How do players make payments',
    answer: 'TennisBrain uses a time based credit system for private coaching which allows players to book their coaches time slots on their credit. Group sessions are paid for directly.',
  },
  {
    question: 'Are there any fees to use the platform ',
    answer: 'A small fee is applied to coaches for the use of our payment provider Stripe, with TennisBrain receiving 2% on transactions',
  },
  {
    question: 'Can any type of coach use the platform',
    answer: 'In practice you can use it for any type of coaching program, but will just have to put up with TennisBrain as the name',
  },
  {
    question: 'Can coaches expect new students to come through the platform ',
    answer: 'It is suggested that coaches encourage their existing students to utilise the platform first. As the userbase of the platform grows, a coach may increase their client base',
  },
  {
    question: 'How do players use the platform',
    answer: `1) Sign up as a player
2) Add details of players relevant to your account
3) Select your coach
4) Add funds into the account
5) Make a booking directly into your coach's calendar`,
  },
  {
    question: 'How do coaches use the platform',
    answer: `1) Sign up as a coach
2) Add your bank account details to receive payments
3) Set your availability
4) Add exercises if you wish to track these for your students
5) Score your sessions
    `,
  },
  {
    question: 'Will you be adding functionality to improve analytics',
    answer: 'This is a new platform, and the very 1st step is to collect data. As more data is captured, additional reporting will be provided',
  },
];

const PageFAQ: FC<PageFAQProps> = ({ className = "" }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div
      className={`nc-PageFAQ overflow-hidden ${className}`}
      data-nc-id="PageFAQ"
    >
      <Helmet>
        <title>FAQ || Tennisbrain</title>
      </Helmet>
      <div className="">
        {/* <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          FAQ
        </h2> */}
        <div className="container mx-auto mt-10">
          <h1 className="text-3xl font-bold mb-7">Frequently Asked Questions</h1>
          {faqData.map((item, index) => (
            <div key={index} className="mb-6">
              <div
                className="border-b border-gray-200 cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <h2 className="text-xl font-semibold mb-2">{item.question}</h2>
              </div>
              {activeIndex === index && (
                <div className="mt-2">
                  {/* {item.answer} */}
                  {item.answer.split('\n').map((line, i) => (
                    <div key={i}>{line}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <BackgroundSection />
          <SectionPromo1 />
        </div>
      </div>
    </div>
  );
};

export default PageFAQ;
