import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `TECHNICAL`,
    job: "Establishing the important building blocks to develop individual styles of play",
    avatar: require('../../images/migrate/7.png')
    // "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "4",
    name: `PHYSICAL`,
    job: "Building endurance and skill through specific fitness drills and exercises",
    avatar: require('../../images/migrate/16.png')
    // "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "3",
    name: `TACTICAL`,
    job: "Improving decision-making and reacting to in-game situations",
    avatar: require('../../images/migrate/10.png')
    // "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "2",
    name: `MENTAL`,
    job: "Developing mental and emotional resilience on and off the court",
    avatar: require('../../images/migrate/20.png')
    // "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="Tennis is not just about hitting balls over the net. Tennis is continuous with a wide range of educational areas to cover, so it is vitally important for a coach to stay on top of their schedule and content to maximise their students potential."
      >
        Your works
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm flex flex-col justify-between">
            <div className="text-center mb-4">
              <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                {item.name}
              </h3>
              <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                {item.job}
              </span>
            </div>
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover rounded-full"
              src={item.avatar}
            />
          </div>
        ))}
      </div>
      <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400 mt-5">
        Like every player every coach is different, and this is why the platform allows you to create bespoke exercises to utilise in classes, keeping things fresh, data driven, relevant and monitored. It is up to you what you deem important, and what you want to track for your student's development. Through a simple scoring system, you can directly assign and assess your students performance on specific exercises providing insights into their development and to your own coaching programs.
      </span>
    </div>
  );
};

export default SectionFounder;
