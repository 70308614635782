import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import CardDashboard from "components/CardCategories/CardDashboard";
const PageDashboard: React.FC = () => {

  return (
    <div className="nc-Page404">
      <Helmet>
        <title>Blank page || Tennisbrain</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            Dashboard Page.{" "}
          </span>
        </header>
        <div className="mt-1">
          <div className="flex flex-row flex-nowrap gap-x-2 bg-gray-200 p-5">
            <CardDashboard name="COMPLETED" desc={"7"} size="large" />
            <CardDashboard name="UPCOMING" desc={"2"} size="large" />
            <CardDashboard name="PLAYERS" desc={"3"} size="large" />
            <CardDashboard name="COACHES" desc={"5"} size="large" />
          </div>
          <div className="min-h-screen bg-gray-100 p-5">
            <div className="bg-white shadow rounded-lg p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

                <div className="bg-blue-100 p-4 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-700">Player Statistics</h2>
                  <p className="mt-1">Overview of player performance and stats.</p>
                </div>

                <div className="bg-green-100 p-4 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-700">Upcoming Matches</h2>
                  <p className="mt-1">Manage and view future scheduled matches.</p>
                </div>

                <div className="bg-red-100 p-4 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-700">Training Schedule</h2>
                  <p className="mt-1">Plan and track training sessions.</p>
                </div>

                <div className="bg-yellow-100 p-4 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-700">Messages</h2>
                  <p className="mt-1">Communicate with players and staff.</p>
                </div>

                <div className="bg-purple-100 p-4 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-700">Drill Library</h2>
                  <p className="mt-1">Access and organize practice drills.</p>
                </div>

                <div className="bg-pink-100 p-4 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-700">Personal Notes</h2>
                  <p className="mt-1">Keep notes on players and strategies.</p>
                </div>

              </div>
            </div>
          </div>

          <div className="pt-8">
            <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageDashboard;
