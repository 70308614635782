import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";

type SelectionsType = {
  [day: string]: boolean[];
};

const PageAvailability: React.FC = () => {

  const daysOfWeek: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const times: string[] = Array.from({ length: 32 }, (_, index) => {
    const hour = Math.floor(index / 2) + 6; // starting from 6
    const minute = index % 2 === 0 ? '00' : '30';
    return `${String(hour).padStart(2, '0')}:${minute}`;
  }).slice(0, -1); // Adjust the times according to your needs

  const initialSelections: SelectionsType = daysOfWeek.reduce((acc: SelectionsType, day) => {
    acc[day] = new Array(times.length).fill(false);
    return acc;
  }, {});

  const [selections, setSelections] = useState<SelectionsType>(initialSelections);

  const handleCheckboxChange = (day: string, index: number) => {
    setSelections(prevSelections => ({
      ...prevSelections,
      [day]: prevSelections[day].map((checked, i) => i === index ? !checked : checked),
    }));
  };

  return (
    <div className="nc-Page404">
      <Helmet>
        <title>Blank page || Tennisbrain</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center mx-auto space-y-2">
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            PageAvailability.{" "}
          </span>


        </header>
        <div className="mt-1">
          <table className="w-full border-collapse text-center">
            <thead>
              <tr>
                <th className="border p-3">Time</th>
                {daysOfWeek.map(day => (
                  <th className="border p-3" key={day}>{day}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {times.map((time, timeIndex) => (
                <tr key={time}>
                  <td className="border p-3">{time}</td>
                  {daysOfWeek.map(day => (
                    <td className="border p-3" key={day}>
                      <input
                        type="checkbox"
                        id={`checkbox-${day}-${time}`}
                        name={`${day}-${time}`}
                        value={`${day}-${time}`}
                        checked={selections[day][timeIndex]}
                        onChange={() => handleCheckboxChange(day, timeIndex)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pt-8">
            <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  );

}
export default PageAvailability;
