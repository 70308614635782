import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";

type Coach = {
    id: number;
    name: string;
    gender: string;
    club: string;
    clubAddress: string;
    qualifications: string;
};

const coaches: Coach[] = [
    {
        id: 1,
        name: 'coach1 trgt',
        gender: 'male',
        club: 'bge',
        clubAddress: '87 Barn Hill',
        qualifications: 'gbeg',
    },
    {
        id: 2,
        name: 'coach2 coach2',
        gender: 'male',
        club: 'grs',
        clubAddress: '87 Barn Hill',
        qualifications: 'ff',
    },
    {
        id: 3,
        name: 'Xirates Techie',
        gender: 'male',
        club: 'nope club',
        clubAddress: 'no address',
        qualifications: 'nope',
    },
    {
        id: 4,
        name: 'Third Coach',
        gender: 'male',
        club: 'nope club',
        clubAddress: 'coach3@hotmail.com',
        qualifications: 'nope',
    },
];

const PagePlayerCoaches: React.FC = () => {
    const [selectedCoachId, setSelectedCoachId] = useState<number | null>(null);

    const handleRowClick = (coachId: number) => {
        setSelectedCoachId(coachId === selectedCoachId ? null : coachId);
    };

    return (
        <div className="nc-PagePlayerCoaches">
            <Helmet>
                <title>Coaches || Tennisbrain : Player</title>
            </Helmet>
            <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                {/* HEADER */}
                <header className="text-center max-w-2xl mx-auto space-y-2">
                    {/* <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                        COMMING SOON.{" "}
                    </span> */}
                    <h1 className="text-xl font-bold mb-4">Coaches</h1>

                </header>
                <div className="container">
                    <table className="min-w-full bg-white border-collapse border border-gray-300">
                        <thead>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2">Status</th>
                                <th className="border border-gray-300 px-4 py-2">Name</th>
                                <th className="border border-gray-300 px-4 py-2">Gender</th>
                                <th className="border border-gray-300 px-4 py-2">Club</th>
                                <th className="border border-gray-300 px-4 py-2">Club Address</th>
                                <th className="border border-gray-300 px-4 py-2">Qualifications</th>
                            </tr>
                        </thead>
                        <tbody>
                            {coaches.map((coach) => (
                                <tr
                                    key={coach.id}
                                    className={`cursor-pointer ${coach.id === selectedCoachId ? 'bg-blue-200' : ''
                                        }`}
                                    onClick={() => handleRowClick(coach.id)}
                                >
                                    <td className="border border-gray-300 px-4 py-2 items-center">
                                        {selectedCoachId === coach.id && <input type="checkbox" checked />}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">
                                        {coach.name}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">{coach.gender}</td>
                                    <td className="border border-gray-300 px-4 py-2">{coach.club}</td>
                                    <td className="border border-gray-300 px-4 py-2">{coach.clubAddress}</td>
                                    <td className="border border-gray-300 px-4 py-2">{coach.qualifications}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pt-8">
                        <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default PagePlayerCoaches;
