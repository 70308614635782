import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/promo2.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import backgroundLineSvg from "images/Moon.svg";
import Badge from "shared/Badge/Badge";

export interface SectionPromo2Props {
  className?: string;
}

const SectionPromo2: FC<SectionPromo2Props> = ({ className = "lg:pt-10" }) => {
  return (
    <div className={`nc-SectionPromo2 ${className}`}>
      <div className="relative flex flex-col lg:flex-row lg:justify-end bg-yellow-50 dark:bg-slate-800 rounded-2xl sm:rounded-[40px] p-4 pb-0 sm:p-5 sm:pb-0 lg:p-24">
        <div className="absolute inset-0">
          <img
            className="absolute w-full h-full object-contain dark:opacity-5"
            src={backgroundLineSvg}
            alt="backgroundLineSvg"
          />
        </div>

        <div className="lg:w-[45%] max-w-lg relative">
          {/* <Logo className="w-28" /> */}
          <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl 2xl:text-6xl mt-6 sm:mt-10 !leading-[1.13] tracking-tight mt-0">
            PLAYERS
          </h2>
          <span className="block mt-6 text-slate-500 dark:text-slate-400">
            No matter your age there will always be areas to improve, and your coach will play a crucial role in your development.
          </span>
          <ul className="space-y-4 mt-10">
            <li className="flex items-center space-x-4">
              <Badge color="purple" name="01" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
                Junior - Red Ball - Age 5-7
              </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge name="02" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
                Junior - Orange Ball - Age 7-9
              </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge color="red" name="03" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
                Junior - Green Ball - Age 9-11
              </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge color="purple" name="04" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
                Junior - Adult Ball - Age 11+
              </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge name="05" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
                Adult - Beginner
              </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge color="red" name="06" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
                Adult - Intermediate
              </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge color="red" name="07" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
                Adult - Advanced
              </span>
            </li>
          </ul>
          {/* <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
            <ButtonPrimary
              href="/page-search"
              className="dark:bg-slate-200 dark:text-slate-900"
            >
              Discover more
            </ButtonPrimary>
          </div> */}
        </div>
        <NcImage
          containerClassName="relative block lg:absolute lg:left-0 lg:bottom-0 mt-10 lg:mt-0 max-w-xl lg:max-w-[calc(55%-40px)]"
          src={rightImgDemo}
        />
      </div>
    </div>
  );
};

export default SectionPromo2;
